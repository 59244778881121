@if (form) {
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title pull-left">{{ title }}</h5>
      <mat-icon (click)="onCancel()" class="">{{ 'close' }}</mat-icon>
    </div>

    <div class="modal-body" [formGroup]="form">
      <div class="row" *ngIf="wrongFileType">
        <div class="col">
          <app-alert [type]="alertTypes.Error">
            {{ 'errors.wrongFileType' | transloco }}
          </app-alert>
        </div>
      </div>
      @if (!isAnyFileSelected) {
        <div class="row">
          <div class="col">
            <ngx-file-drop
              (onFileDrop)="dropped($event)"
              [multiple]="true"
              accept="{{ constants.fileUploaderAcceptedFormats.join(',') }}"
            >
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <p>
                  {{ 'text.dragAndDropMultiDocx' | transloco }}
                </p>
                <p>
                  <button type="button" (click)="openFileSelector()">
                    {{ 'buttons.browse' | transloco }}
                  </button>
                </p>
              </ng-template>
            </ngx-file-drop>
          </div>
        </div>
      } @else {
        <app-files-list
          [files]="files"
          [isOneFileSelected]="isOneFileSelected"
          [isLotsFileSelected]="isLotsFileSelected"
        ></app-files-list>

        <span *ngIf="isOneFileSelected">
          <div class="row mt-2">
            <div class="col">
              <strong>{{ 'labels.docProps' | transloco }}</strong>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.docName' | transloco }}
            </div>
            <div class="col">
              <app-input
                placeholder="{{ 'labels.docName' | transloco }}"
                formControlName="documentName"
              ></app-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.docLanguage' | transloco }}
            </div>
            <div class="col">
              <app-single-select-autocomplete
                class="w100"
                formControlName="languageId"
                [autocompleteOptions]="contentLanguages"
                optionLabel="displayName"
              ></app-single-select-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.docType' | transloco }}
            </div>
            <div class="col d-flex">
              <app-single-select-autocomplete
                class="w100"
                formControlName="documentType"
                [autocompleteOptions]="docTypeItems"
              ></app-single-select-autocomplete>
              <app-button
                class="after-control"
                [type]="btnTypes.Flat"
                [disabled]="!!isUploading"
                label="{{ 'labels.createNew' | transloco }}"
                [size]="btnSizes.Basic"
                (onClick)="openNewDocTypeForm()"
              ></app-button>
            </div>
          </div>

          @if (creatingNewDocType && addDocTypeForm) {
            <div class="arrow-up"></div>
            <div class="new-item-form" [formGroup]="addDocTypeForm">
              <div class="row">
                <div class="col">
                  {{ 'text.createNewDocType' | transloco }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <app-input
                    label="{{ 'labels.docType' | transloco }}"
                    placeholder="{{ 'labels.docType' | transloco }}"
                    formControlName="newDocumentType"
                    [id]="'newDocumentType'"
                  ></app-input>
                </div>
              </div>
              <div class="new-item-form__footer">
                <app-button
                  automation-id="create-btn"
                  [type]="btnTypes.Flat"
                  label="{{ 'buttons.add' | transloco }}"
                  [disabled]="addDocTypeForm.invalid"
                  [size]="btnSizes.Basic"
                  (onClick)="onConfirmNewDocType()"
                ></app-button>
                <app-button
                  automation-id="create-btn"
                  [type]="btnTypes.Stroked"
                  label="{{ 'buttons.cancel' | transloco }}"
                  [size]="btnSizes.Basic"
                  (onClick)="onCancelNewDocType()"
                ></app-button>
              </div>
            </div>
          }

          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.counterparties' | transloco }}
            </div>
            <div class="col d-flex">
              <app-multi-select-autocomplete
                class="w100"
                formControlName="counterparties"
                [autocompleteOptions]="conterPartyItems"
                [hiddenOptions]="hiddenConterPartyItems"
              ></app-multi-select-autocomplete>
              <app-button
                class="after-control"
                [type]="btnTypes.Flat"
                label="{{ 'labels.createNew' | transloco }}"
                [disabled]="!!isUploading"
                [size]="btnSizes.Basic"
                (onClick)="openNewPartyForm()"
              ></app-button>
            </div>
          </div>

          @if (creatingNewParty && addPartyForm) {
            <div class="arrow-up"></div>
            <div class="new-item-form" [formGroup]="addPartyForm">
              <div class="row">
                <div class="col">
                  {{ 'text.createNewParty' | transloco }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <app-input
                    label="{{ 'labels.counterparty' | transloco }}"
                    placeholder="{{ 'labels.counterparty' | transloco }}"
                    formControlName="newPartyName"
                    [id]="'newPartyName'"
                  ></app-input>
                </div>
              </div>
              <div class="new-item-form__footer">
                <app-button
                  automation-id="create-btn"
                  [type]="btnTypes.Flat"
                  label="{{ 'buttons.add' | transloco }}"
                  [disabled]="addPartyForm.invalid"
                  [size]="btnSizes.Basic"
                  (onClick)="onConfirmNewParty()"
                ></app-button>
                <app-button
                  automation-id="create-btn"
                  [type]="btnTypes.Stroked"
                  label="{{ 'buttons.cancel' | transloco }}"
                  [size]="btnSizes.Basic"
                  (onClick)="onCancelNewParty()"
                ></app-button>
              </div>
            </div>
          }

          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.myPosition' | transloco }}
            </div>
            <div class="col">
              <app-radio formControlName="myPosition" [items]="positionItems"></app-radio>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-3 control-label">
              {{ 'labels.status' | transloco }}
            </div>
            <div class="col">
              <app-single-select-autocomplete
                formControlName="status"
                [autocompleteOptions]="docStatusItems"
              ></app-single-select-autocomplete>
            </div>
          </div>
        </span>
      }
    </div>
    <div class="modal-footer">
      <app-button
        automation-id="create-btn"
        [type]="btnTypes.Flat"
        label="{{ 'buttons.add' | transloco }}"
        [disabled]="!isConfirmAvailable || !!isUploading"
        [size]="btnSizes.Basic"
        (onClick)="onConfirm()"
      ></app-button>
      <app-button
        automation-id="create-btn"
        [type]="btnTypes.Stroked"
        label="{{ 'buttons.cancel' | transloco }}"
        [disabled]="!!isUploading"
        [size]="btnSizes.Basic"
        (onClick)="onCancel()"
      ></app-button>
    </div>
  </div>
}
