import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DocumentsFeature } from './selectors';
import {
  DocumentCommonDto,
  GetDocumentDto,
  GetDocumentDtoExtended,
} from '../models/documents.model';
import { SendReportDto } from 'src/app/shared/services/reporting-api.service';

export const DocumentsActions = createActionGroup({
  source: DocumentsFeature,
  events: {
    'Get Documents': emptyProps(),
    'Load Documents Fail': emptyProps(),
    'Load Documents': emptyProps(),
    'Set Documents': props<{ documents: GetDocumentDtoExtended[] }>(),
    'Set Uploading Files Count': props<{ count: number }>(),
    'Clear Uploaded Files': emptyProps(),
    'Create Document': props<{ model: DocumentCommonDto }>(),
    'Create Document Success': props<{ document: GetDocumentDto }>(),
    'Create Document Fail': props<{ model: DocumentCommonDto }>(),
    'Create Document Cancel': props<{ pristine: boolean }>(),
    'Create Document Cancelled': emptyProps(),
    'Clear Create Document Cancelled': emptyProps(),
    'Close Creating Document Modal': emptyProps(),
    'Delete Document': props<{ uniqueId: string }>(),
    'Delete Document Confirmed': props<{ uniqueId: string }>(),
    'Delete Document Success': emptyProps(),
    'Delete Document Fail': emptyProps(),
    'Delete Document Cancel': emptyProps(),
    'Load Document': props<{ uniqueId: string }>(),
    'Load Document Fail': emptyProps(),
    'Set Document': props<{ document: GetDocumentDto }>(),
    'Edit Document': props<{ model: DocumentCommonDto; uniqueId: string }>(),
    'Edit Document Success': props<{ document: GetDocumentDto }>(),
    'Edit Document Fail': emptyProps(),
    'Edit Document Cancel': props<{ pristine: boolean }>(),
    'Edit Document Cancelled': emptyProps(),
    'Clear Edit Document Cancelled': emptyProps(),
    'Close Editing Document Modal': emptyProps(),
    'Send Report': props<{ report: SendReportDto }>(),
    'Send Report Success': props<{ report: SendReportDto; response: any }>(),
    'Send Report Fail': emptyProps(),
    'Send Report Cancel': props<{ pristine: boolean }>(),
    'Send Report Cancelled': emptyProps(),
    'Clear Send Report Cancelled': emptyProps(),
    'Close Sending Report Modal': emptyProps(),
  },
});
