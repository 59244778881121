// Value examples for a user belonging to the @storyshaper.io domain signed in at https://app.dev.autolex.ai/
// type: 13: Contrat de prestation de services, 12: Service Agreement
// userPositionId: 1: sending / 2: receiving
// parties: 4: Contoso, 7, 7: Genco pura, 6: Quantun Innovations, 5: Channel
// status:  1: In progress,  2: Validated, 3: Signed, 4: Dropped
// languageId: 1: English, 2: French

export const collections = {
  batchDocsUploadList: [
    {
      name: 'CHANEL - EN - UK GBL - IT MSA',
      type: 12,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 1,
    },
    {
      name: 'CHANEL - EN - SPEC TERMS FOR DIGITAL SERVICES',
      type: 12,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 1,
    },
    {
      name: 'VENDOR - EN - IT SERVICES - Anonymised Version',
      type: 12,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 1,
    },
    {
      name: 'VENDOR - EN - CLOUD AGREEMENT',
      type: 12,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 1,
    },
    {
      name: 'CHANEL - FR MSA - Contrat cadre de service IT',
      type: 13,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 2,
    },
    {
      name: 'Document1',
      type: 13,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 2,
    },
    {
      name: 'Document2',
      type: 13,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 2,
    },
    {
      name: 'Document3',
      type: 13,
      userPositionId: 1,
      parties: [4],
      status: 1,
      languageId: 2,
    },
  ],
};
